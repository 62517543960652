// Dependencies
import styled from "styled-components";

export const Wrapper = styled.div `
  /* background-color: coral; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // MOBILE
  @media only screen and (max-width: 767px) {
    /* background-color: red; */
    /* margin-bottom: 2rem; */
  }
`;

export const Content = styled.div `
  height: 70%;
  width: 85%;
  /* background: blue; */
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: space-between;
  align-items: center;

  // MOBILE
  @media only screen and (max-width: 767px) {
    /* background-color: yellow; */
    width: 100%;
    height: 100%;
    padding: 2rem 0.5rem;
  }
`;

export const Top = styled.div `
  /* background-color: green; */
  height: 50%;
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  // MOBILE
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    height: 100%;
    gap: 3rem;
    margin: 2rem 0;
    /* background-color: red; */
  }
`;