import React from "react";

// Components
import MainRouter from "../Routes";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";

// Styles
import { LayoutStyle, Right } from "./Layout.Styles";

const Layout = () => {
  return (
    <>
      <LayoutStyle>
        <Sidebar />
        <Right>
          <MainRouter />
          <Footer />
        </Right>
      </LayoutStyle>
    </>
  );
};

export default Layout;
