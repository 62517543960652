// Dependencies
import styled from "styled-components";

export const Wrapper = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  /* height: 219.48px; */
  background: var(--bg-dark);
  border: 0.910699px solid #c4c4c4;
  border-radius: 18.214px;
  font-family: "Inter";
  font-style: normal;
  color: white;

  // MOBILE
  @media only screen and (max-width: 767px) {
    /* background-color: red; */
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    background: none;
    border: none;
  }
`;