// Dependencies
import styled from "styled-components";

export const Wrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
  /* background: var(--bg-dark); */
  color: white;
  transition: all 300ms ease-in;
  /* background: red; */
  cursor: crosshair;

  // MOBILE
  @media only screen and (max-width: 767px) {
    /* background-color: red; */
    gap: 1.5rem;
    background: var(--bg-dark);
    border-radius: 18.214px;
  }

  .top {
    /* height: 50%; */
    text-align: left;
    /* width: 100%; */
    width: 90px;
    /* background: red; */
    position: relative;

    // MOBILE
    @media only screen and (max-width: 767px) {
      /* background-color: red; */
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ringNumber {
    position: absolute;
    top: 33px;
    /* left: 50%; */
    left: 35px;
    color: #fff;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 600;

    // MOBILE
    @media only screen and (max-width: 767px) {
      /* background-color: red; */
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 34px;
      left: 0;
    }
  }

  .bottom {
    height: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
  }

  .title {
    color: white;
    font-weight: 700;
    font-size: 24px;
    line-height: 99%;
    text-align: left;
    width: 100%;
    /* background: red; */

    // MOBILE
    @media only screen and (max-width: 767px) {
      /* background-color: red; */
      text-align: center;
    }
  }

  .description {
    color: #e0f7fa;
    font-weight: 400;
    font-size: 13px;
    line-height: 128.3%;
    text-align: left;
    width: 100%;
    /* background: red; */

    // MOBILE
    @media only screen and (max-width: 767px) {
      /* background-color: red; */
      text-align: center;
    }
  }

  &:hover {
    background: #fff;

    .title {
      color: var(--bg-dark);
    }

    .description {
      color: var(--bg-dark);
    }

    .ringNumber {
      color: var(--bg-dark);
    }
  }

  // Media query //hide in big screen
  /* @media (max-width: 700px) {
    display: none;
  } */
`;