// Dependencies
import styled from "styled-components";

export const Wrapper = styled.div `
  /* background-color: pink; */
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: "Inter";
  font-style: normal;
  color: var(--bg-dark);

  // MOBILE
  @media only screen and (max-width: 767px) {
    text-align: center;
    /* background: green; */
    width: 100%;
    gap: 1.5rem;
  }

  .bigText {
    /* background-color: red; */
    font-weight: 700;
    font-size: 86px;
    line-height: 97%;
    letter-spacing: -0.02em;

    // MOBILE
    @media only screen and (max-width: 767px) {
      font-size: 40px;
      /* background-color: red; */

      img {
        width: 30px;
      }
    }

    span {
      background: -webkit-linear-gradient(#141033, #4dd0e2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .smallText {
    /* background-color: blue; */
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #4f517b;
    text-align: left;

    // MOBILE
    @media only screen and (max-width: 767px) {
      /* background-color: red; */
      text-align: center;
    }
  }
`;