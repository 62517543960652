import React from "react";

// styles
import { Image, Wrapper } from "./Clients.Styles";

// icons
import spikk from "../../assets/image/clients/spikk.png";
import gloed from "../../assets/image/clients/gloed.png";
import prevet from "../../assets/image/clients/prevet.png";
import grabbets from "../../assets/image/clients/grabbets.png";
import hostout from "../../assets/image/clients/hostout.png";
import feedvote from "../../assets/image/clients/feedvote.png";

const Clients = () => {
  return (
    <Wrapper>
      <div className="title">Our Clients</div>
      <div className="bottom">
        <a href="https://spikk.co">
          <Image src={spikk} alt="" width={"75.51px"} height={"29px"} />
        </a>
        <a href=" https://gloed.co">
          <Image src={gloed} alt="" width={"100px"} height={"30.23px"} />
        </a>
        <a href="https://prevets.com">
          <Image src={prevet} alt="" width={"67.35px"} height={"30px"} />
        </a>
        <a href="/">
          <Image src={grabbets} alt="" width={"131.24px"} height={"29px"} />
        </a>
        <a href="https://feedvote.com">
          <Image src={feedvote} alt="" width={"127.43px"} height={"21px"} />
        </a>
        <a href="https://hostout.co">
          <Image src={hostout} alt="" width={"48.84px"} height={"30px"} />
        </a>
      </div>
    </Wrapper>
  );
};

export default Clients;
