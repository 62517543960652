import React from "react";
import { Link } from "react-router-dom";

// styles
import {
  Wrapper,
  SidebarTop,
  SidebarLogo,
  SidebarDown,
} from "./Sidebar.Styles";

// logo
import logo from "../../assets/image/logo1.png";
import mail from "../../assets/image/sidebar/mail.png";
import insta from "../../assets/image/sidebar/insta.png";

const Sidebar = () => {
  return (
    <>
      <aside>
        <Wrapper id="wrapper">
          <SidebarTop>
            <SidebarLogo>
              <Link href="/">
                <img src={logo} alt="logo" />
              </Link>
            </SidebarLogo>
          </SidebarTop>

          <SidebarDown>
            <a href="/">
              <img src={insta} alt="" />
            </a>
            <a href="mailto:hi@cargle.org">
              <img src={mail} alt="" />
            </a>
          </SidebarDown>
        </Wrapper>
      </aside>
    </>
  );
};

export default Sidebar;
