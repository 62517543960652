// styles
import { Link } from "react-router-dom";
import { Wrapper, Content, Top, Bottom } from "./Footer.Styles";

// logo
// import logo from "../../public/images/GIITSC_LOGO.png";
import mail from "../../assets/image/footer/mail.png";
import insta from "../../assets/image/footer/insta.png";

const Footer = () => {
  return (
    <Wrapper>
      <Content>
        <Top>
          <div className="topLeft">
            <div className="topLeftTitle">Cargle</div>
            <div className="topLeftLinks">
              <a href="mailto:hi@cargle.org">
                <img src={mail} alt="" />
              </a>
              <a href="/">
                <img src={insta} alt="" />
              </a>
            </div>
          </div>
          <div className="topRight">
            <div className="topRightTitle">Quicklinks</div>
            <div className="topRightLinks">
              <ul>
                <li>
                  <Link href="/about">About Us (coming soon)</Link>
                </li>
                <li>
                  <Link href="/clients">Our clients</Link>
                </li>
                <li>
                  <Link href="/testimonials">Testimonials (coming soon)</Link>
                </li>
              </ul>
            </div>
          </div>
        </Top>
        <Bottom className="text-white">
          &#169; 2022 All rights Reservered
          <Link href="/">Cargle.org</Link>
        </Bottom>
      </Content>
    </Wrapper>
  );
};

export default Footer;
