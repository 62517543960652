// Dependencies
import styled from "styled-components";

export const LayoutStyle = styled.div`
  background-color: var(--bg-color);
  /* background-color: coral; */
  min-height: 100vh;
  display: flex;
  /* gap: 0.5rem; */

  // MOBILE
  @media only screen and (max-width: 767px) {
    /* display: none; */
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  background-color: red;
  width: 100%;
  margin-left: 146px;
  height: 100vh;
  overflow-y: scroll;
  background: linear-gradient(
    47.04deg,
    rgba(255, 222, 233, 0.5) 20.81%,
    rgba(181, 255, 252, 0.5) 89.92%
  );

  @media (max-width: 768px) {
    margin-left: 90px;
  }

  // MOBILE
  @media only screen and (max-width: 767px) {
    margin-left: 0;
  }
`;
