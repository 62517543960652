import React from "react";

// styles
import { Wrapper, Content, Top } from "./HeroSection.Styles";

// components
import IntroText from "../introText/IntroText";
import LetsChat from "../letsChat/LetsChat";
import OurServices from "../ourServices/OurServices";
import Clients from "../clients/Clients";
// import Footer from "../footer/Footer";

const HeroSection = () => {
  return (
    <Wrapper>
      <Content>
        <Top>
          <IntroText />
          <LetsChat />
        </Top>
        <OurServices />
        <Clients />
      </Content>
      {/* <Footer /> */}
    </Wrapper>
  );
};

export default HeroSection;
