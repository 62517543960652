import React from "react";
// import { Link, NavLink } from "react-router-dom";

//styles
import { Wrapper } from "./LetsChat.Styles";

// icons
import chatIcon from "../../assets/image/letsChat/chatIcon.png";
import exportIcon from "../../assets/image/letsChat/export.png";

const LetsChat = () => {
  return (
    <Wrapper href="https://wa.me/07034560055">
      <div className="top">
        <img src={chatIcon} alt="" />
      </div>
      <div className="bottom">
        <div className="title">
          Let's chat <img src={exportIcon} alt="" />
        </div>
        <div className="description">And answer any questions you have</div>
      </div>
    </Wrapper>
  );
};

export default LetsChat;
