import React from "react";

// styles
import { Wrapper } from "./ServiceCard.Styles";

// icons
import rings from "../../assets/image/serviceCard/rings.png";

const ServiceCard = ({ number, title, description }) => {
  return (
    <Wrapper>
      <div className="top">
        <img src={rings} alt="" />
        <div className="ringNumber">{number}</div>
      </div>
      <div className="bottom">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </Wrapper>
  );
};

export default ServiceCard;
