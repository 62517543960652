import styled from "styled-components";

export const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-dark);
  padding: 2rem;

  a {
    text-decoration: none;
    color: inherit;
  }

  // MOBILE
  @media only screen and (max-width: 767px) {
    /* background: red; */
  }
`;

export const Overlay = styled.div `
  /* background-color: rgba(122, 122, 122, 0.3); */
  height: 100%;
  width: 100%;
  padding: 3rem 0;
  /* padding-top: 60px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(32, 33, 36, 0.4);
  backdrop-filter: blur(2px);

  // TAB
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding-top: 0px;
  }

  // MOBILE
  @media only screen and (max-width: 767px) {
    padding-top: 0px;
  }
`;

export const Content = styled.div `
  /* background-color: blue; */
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // TAB
  @media only screen and(min-width: 768px) and (max-width: 991px) {
  }

  // MOBILE
  @media only screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
    /* background: red; */
  }
`;

export const Top = styled.div `
  /* background-color: yellow; */
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: top;
  padding: 1rem 0;

  // MOBILE
  @media only screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
    margin-left: 0rem;
    gap: 3rem;
    /* background: red; */
  }

  .topLeft {
    display: flex;
    flex-direction: column;
    gap: 0.77777rem;
    width: 15rem;
    /* background: red; */

    // MOBILE
    @media only screen and (max-width: 767px) {
      /* background-color: red; */
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    .topLeftTitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      color: #ffffff;
      width: 80%;
      /* background: green; */

      // MOBILE
      @media only screen and (max-width: 767px) {
        width: 100%;
        /* background: green; */
      }
    }

    .topLeftLinks {
      /* background: yellow; */
      display: flex;
      gap: 1rem;

      img {
        transition: all 300ms ease-in;
        padding-bottom: 0.5rem;
        cursor: pointer;

        &:hover {
          color: #fff;
          border-bottom: 1px solid white;
        }
      }
    }

    // MOBILE
    @media only screen and (max-width: 767px) {
      width: 100%;

      /* background: red; */
    }
  }

  .topRight {
    display: flex;
    flex-direction: column;
    gap: 0.77777rem;
    /* width: 15rem; */
    /* background: red; */

    // MOBILE
    @media only screen and (max-width: 767px) {
      width: 100%;
      /* background: red; */
    }

    .topRightTitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      color: #ffffff;
      /* width: 80%; */
      text-align: end;

      // MOBILE
      @media only screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        /* background: yellow; */
      }
    }

    .topRightLinks {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      /* width: 80%; */
      color: grey;
      /* margin-right: 1rem; */

      // MOBILE
      @media only screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        /* background: yellow; */
      }

      ul {
        /* background: green; */
        list-style: none;
        text-align: right;
        display: flex;
        padding: 0;
        flex-direction: column;
      }

      li {
        text-align: end;
        margin-bottom: 0.5rem;
        color: grey;
        transition: all 300ms ease-in;

        // MOBILE
        @media only screen and (max-width: 767px) {
          text-align: center;
          /* background: yellow; */
        }
      }

      li a {
        /* text-align: center; */
        margin-bottom: 0.5rem;
        color: grey;
        transition: all 300ms ease-in;

        &:hover {
          color: #fff;
          border-bottom: 1px solid white;
        }
      }
    }
  }
`;

export const Bottom = styled.div `
  /* background-color: pink; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #fff;
  padding: 2rem 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;

  a {
    transition: all 300ms ease-in;

    // MOBILE
    @media only screen and (max-width: 767px) {
      display: none;
    }

    &:hover {
      color: #ccc;
      border-bottom: 1px solid white;
    }
  }

  // MOBILE
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
`;