import React from "react";
import { Wrapper } from "./IntroText.Styles";

// icon
import introIcon from "../../assets/image/introIcon.png";

const IntroText = () => {
  return (
    <>
      <Wrapper>
        <div className="bigText">
          Build a <span>Successful</span> Business{" "}
          <img src={introIcon} alt="" />
        </div>
        <div className="smallText">
          Let’s work together to <b> build and scale your business </b> to a
          measurable scale
        </div>
      </Wrapper>
    </>
  );
};

export default IntroText;
