// Dependencies
import styled from "styled-components";

export const Wrapper = styled.a`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 225.85px;
  height: 219.48px;
  background: #ffffff;
  border: 0.910699px solid #c4c4c4;
  border-radius: 18.214px;
  font-family: "Inter";
  font-style: normal;
  padding: 1.5rem;
  gap: 1rem;
  text-decoration: none;
  transition: all 300ms ease-in;

  a {
    text-decoration: none;
    color: inherit;
  }

  .top {
    margin-bottom: 1rem;
    background: var(--bg-dark);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 35px;
      height: 35px;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .title {
    font-weight: 700;
    font-size: 21.8568px;
    line-height: 107.8%;
  }

  .description {
    word-wrap: wrap;
    font-weight: 400;
    font-size: 14.5712px;
    line-height: 17px;
  }

  &:hover {
    background: var(--bg-dark);

    .top {
      background: transparent;
      border: 2px solid #fff;
    }

    .title {
      color: #fff;
    }

    .description {
      color: #fff;
    }
  }
`;
