import { AppProvider } from "../src/context/AppContext";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/index.css";
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/Layout";

function App() {
  return (
    <div className="App">
      <AppProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <h1>Cargle</h1> */}
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

export default App;
