import React from "react";

// styles
import { Wrapper } from "./OurServices.Styles";
import ServiceCard from "../../widgets/serviceCard/ServiceCard";

const OurServices = () => {
  return (
    <>
      <Wrapper>
        <ServiceCard
          number={"01"}
          title={"Consultancy"}
          description={
            "We will understand your business needs, and provide advice on what to explore/implement"
          }
        />
        <ServiceCard
          number={"02"}
          title={"Design"}
          description={
            "We imagine and create products that solve users' problems and needs"
          }
        />
        <ServiceCard
          number={"03"}
          title={"Dev"}
          description={
            "We bring your ideas to life through proper software development"
          }
        />
      </Wrapper>
    </>
  );
};

export default OurServices;
