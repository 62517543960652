import styled from "styled-components";

export const Wrapper = styled.div `
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  height: 50vh;
  /* background: red; */
  font-family: "Inter";
  font-style: normal;
  color: white;
  margin: 2rem 0;

  // MOBILE
  @media only screen and (max-width: 767px) {
    /* background-color: red; */
    height: 100%;
    gap: 2rem;
  }

  .title {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    /* width: 247px; */
    /* height: 68px; */
    background: #ffffff;
    border: 1px solid #b2ebf2;
    border-radius: 220.236px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
  }

  .bottom {
    display: flex;
    justify-content: space-evenly;
    gap: 3rem;
    width: 100%;
    margin: 1rem auto;

    // MOBILE
    @media only screen and (max-width: 767px) {
      /* background-color: red; */
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`;

export const Image = styled.img `
  width: ${(props) => props.width || "145px"};
  height: ${(props) => props.height || "50px"};
  transition: all 200ms ease-in;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
  }
`;